/**
 * 企业账号管理-企业账号状态
 */
export const enterpriseStatus = [
  {
    label: '未开始',
    value: 0
  },
  {
    label: '正常',
    value: 1
  },
  {
    label: '已过期',
    value: 2
  }
]
/**
 * 企业账号管理-企业账号状态
 */
export const enterpriseAccountType = [
  {
    label: '购买',
    value: 1
  },
  {
    label: '赠送',
    value: 2
  },
  {
    label: '试用',
    value: 3
  }
]
/**
 * 企业账号管理  开通平台
 */
export const platformType = [
  {
    label: '幸福绩效',
    value: 'HAPPINESS_CHECK'
  },
  {
    label: '三清宝',
    value: 'THREE_REPORT_APP'
  }
]

/**
 * 菜单icon
 */
export const menuIcon = [
  { label: 'iconyewuzhongtai', value: 'iconyewuzhongtai' },
  { label: 'iconweidupeizhi', value: 'iconweidupeizhi' },
  { label: 'iconpeizhizhongxin', value: 'iconpeizhizhongxin' },
  { label: 'iconzuzhijixiao', value: 'iconzuzhijixiao' },
  { label: 'iconminzhuhuping', value: 'iconminzhuhuping' },
  { label: 'iconchuangxinchengguopeizhi', value: 'iconchuangxinchengguopeizhi' },
  { label: 'icontuanduigongxian', value: 'icontuanduigongxian' },
  { label: 'icongongzuomubiao', value: 'icongongzuomubiao' },
  { label: 'iconsanqinglianghui', value: 'iconsanqinglianghui' },
  { label: 'iconkehumanyi', value: 'iconkehumanyi' },
  { label: 'iconrongyubang', value: 'iconrongyubang' },
  { label: 'iconxunzhangguanli', value: 'iconxunzhangguanli' },
  { label: 'icontebiekoufen', value: 'icontebiekoufen' },
  { label: 'iconchixufendoujiang', value: 'iconchixufendoujiang' },
  { label: 'iconyuangongguanhuai', value: 'iconyuangongguanhuai' },
  { label: 'iconjixiaoweidujifenshupeizhi', value: 'iconjixiaoweidujifenshupeizhi' },
  { label: 'iconhangweiguifanpeizhi', value: 'iconhangweiguifanpeizhi' },
  { label: 'iconjichuweidupeizhi', value: 'iconjichuweidupeizhi' },
  { label: 'icondaibanxiaoxituisongpeizhi', value: 'icondaibanxiaoxituisongpeizhi' },
  { label: 'iconzuzhijiagouguanli', value: 'iconzuzhijiagouguanli' },
  { label: 'iconquanxianpeizhi', value: 'iconquanxianpeizhi' },
  { label: 'iconshengjiangtaopeizhi', value: 'iconshengjiangtaopeizhi' },
  { label: 'iconxunzhangpeizhi', value: 'iconxunzhangpeizhi' },
  { label: 'iconquanxianguanli', value: 'iconquanxianguanli' },
  { label: 'iconxiaoxiguanli', value: 'iconxiaoxiguanli' },
  { label: 'iconappbanbenguanli', value: 'iconappbanbenguanli' },
  { label: 'iconminganciku', value: 'iconminganciku' },
  { label: 'iconcaozuoyuanguanli', value: 'iconcaozuoyuanguanli' },
  { label: 'iconcaozuorizhi', value: 'iconcaozuorizhi' },
  { label: 'iconxieyiguanli', value: 'iconxieyiguanli' },
  { label: 'iconzidianguanli', value: 'iconzidianguanli' },
  { label: 'iconqiyezhanghaoguanli', value: 'iconqiyezhanghaoguanli' },
  { label: 'iconcaidanguanli', value: 'iconcaidanguanli' }
]
/**
 * 平台类型
 */
export const platFormOptions = [
  { value: 1, label: 'API' },
  { value: 2, label: '运营平台' },
  { value: 3, label: '企业后台' }
]
/**
 * 菜单类型
 */
export const resourcesTypeOptions = [
  { value: 1, label: '菜单' },
  { value: 2, label: '按钮' }
]

/**
 * 版本管理-终端
 */
export const terminalOptions = [
  //{ value: 0, label: '全部' },
  { value: 1, label: '安卓' },
  { value: 2, label: 'IOS' }
]
/**
 * 消息模板
 */
//短信类型
export const smsTypeOptions = [
  { id: 1, value: '验证码短信' },
  { id: 2, value: '业务短信' }
]
//消息类型
export const msgTypeOptions = [
  { id: 1, value: '通知消息' },
  { id: 2, value: '待办消息' }
]
//消息种类
export const messageTypeOptions = [
  { id: 1, value: '工作目标' },
  { id: 2, value: '绩效考核' },
  { id: 3, value: '三清两会' },
  { id: 4, value: '民主互评' },
  { id: 5, value: '客户满意' },
  { id: 6, value: '创新成果' },
  { id: 7, value: '团队贡献' },
  { id: 8, value: '其他通知' }
]
//跳转页面
export const jumpOptions = [
  {
    label: '#目标',
    options: [
      {
        value: 101,
        label: '工作目标-待我确认列表页'
      },
      {
        value: 102,
        label: '工作目标-待我考评列表页'
      },
      {
        value: 103,
        label: '工作目标-频道页'
      }
    ]
  },
  {
    label: '#三清',
    options: [
      {
        value: 201,
        label: '三清两会-决议列表页'
      },
      {
        value: 202,
        label: '三清-频道页'
      },
      {
        value: 203,
        label: '两会-频道页'
      },
      {
        value: 204,
        label: '三清-审阅页'
      }
    ]
  },
  {
    label: '#创新成果',
    options: [
      {
        value: 301,
        label: '创新成果-审批列表页'
      },
      {
        value: 302,
        label: '创新成果-我的发起 ‘全部’列表页'
      },
      {
        value: 303,
        label: '创新成果-频道页'
      }
    ]
  },
  {
    label: '#团队贡献',
    options: [
      {
        value: 401,
        label: '团队贡献-审批列表页'
      },
      {
        value: 402,
        label: '团队贡献-我的发起 ‘全部’列表页'
      },
      {
        value: 403,
        label: '团队贡献-频道页'
      }
    ]
  },
  {
    label: '#组织绩效',
    options: [
      {
        value: 501,
        label: '组织绩效-参评组织考评页'
      },
      {
        value: 502,
        label: '组织绩效-个人绩效考评页'
      }
    ]
  },
  {
    label: '#民主互评',
    options: [
      {
        value: 601,
        label: '民主互评-待我评价页'
      },
      {
        value: 602,
        label: '民主互评-待我审批列表页'
      },
      {
        value: 603,
        label: '民主互评-邀请评价人页面'
      },
      {
        value: 604,
        label: '民主互评-收到的评价页面'
      },
      {
        value: 605,
        label: '民主互评-我的团队页面'
      }
    ]
  },
  {
    label: '#客户满意度',
    options: [
      {
        value: 701,
        label: '客户满意度-待我评价页面'
      },
      {
        value: 702,
        label: '客户满意度-待我审批页面'
      },
      {
        value: 703,
        label: '客户满意度-邀请评价人页面'
      },
      {
        value: 704,
        label: '客户满意度-客户满意度人员列表'
      },
      {
        value: 705,
        label: '客户满意度-投诉处理页面'
      },
      {
        value: 706,
        label: '客户满意度-感动处理页面'
      }
    ]
  },
  {
    label: '#其他',
    options: [
      {
        value: 801,
        label: '消息-待办列表页'
      },
      {
        value: 802,
        label: '首页'
      }
    ]
  }
]
