export default {
  data() {
    return {
      page: 1,
      total: 0,
      size: 10,
      loading: false
    }
  },
  methods: {
    handleSizeChange(pageSize) {
      this.size = pageSize
      if (Math.ceil(this.total / this.size) >= this.page) {
        this.getListData()
      }
    },
    handleCurrentChange(pageNum) {
      this.page = pageNum
      this.getListData()
    }
  }
}
