import * as http from '../http'

export default {
  //查询获取 version 列表(通过page分页)
  list: params => {
    return http.post('/version/pageList', params)
  },
  getVersion: params => {
    return http.post('/version/getVersion', params)
  },
  add: params => {
    return http.post('/version/add', params)
  },
  update: params => {
    return http.post('/version/edit', params)
  },
  updateState: params => {
    return http.post('/version/updateState', params)
  }
}
